<template>
  <div class="tw-px-6">
    <v-row>
      <v-col cols="12" class="tw-mt-4 tw-mb-8">
        <p
          class="tw-text-left tw-font-figtree tw-text-22 tw-font-semibold tw-text-dark-green"
        >
          Suggest a stay
        </p>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-form @submit.prevent="save">
            <validation-provider v-slot="{ errors }" name="linkUrl">
              <j-text-field
                color="secondary"
                v-model="linkUrl"
                type="text"
                :error-messages="errors"
                label="Link to stay"
                sublabel="Drop a link and we'll fill in the details"
                class="tw-mt-6"
                customClasses="tw-text-left"
                :loading="metadataPending"
              />
            </validation-provider>
            <p class="j-text-field-label tw-mt-6 tw-mb-2">
              Explore booking options
            </p>
            <p class="tw-text-left tw-text-xs tw-text-light-grey tw-mb-6">
              We earn a small amount when you buy through our links
            </p>
            <div class="tw-flex">
              <a
                href="https://prf.hn/click/camref:1101l3BN3k/pubref:letsjetty/ar:app"
                target="_blank"
                rel="noopener noreferrer"
                class="tw-w-1/2 tw-mr-2"
              >
                <button
                  class="tw-flex tw-items-center tw-justify-between tw-shadow-custom tw-rounded-xl tw-p-4 tw-w-full"
                  type="button"
                >
                  <span class="tw-text-left tw-text-dark-green tw-font-bold"
                    >Expedia</span
                  >
                  <img
                    class="tw-rounded-full"
                    src="@/assets/svgs/arrow-right-dark-background.svg"
                    alt="Go"
                  />
                </button>
              </a>
              <a
                href="https://prf.hn/click/camref:1100l3BNe3/pubref:letsjetty/ar:app"
                target="_blank"
                rel="noopener noreferrer"
                class="tw-w-1/2 tw-ml-2"
              >
                <button
                  class="tw-flex tw-items-center tw-justify-between tw-shadow-custom tw-rounded-xl tw-p-4 tw-w-full"
                  type="button"
                >
                  <span class="tw-text-left tw-text-dark-green tw-font-bold"
                    >VRBO</span
                  >
                  <img
                    class="tw-rounded-full"
                    src="@/assets/svgs/arrow-right-dark-background.svg"
                    alt="Go"
                  />
                </button>
              </a>
            </div>
            <!-- <p class="tw-text-left tw-font-figtree tw-text-xs tw-font-semibold tw-mt-6">Image</p>
            <v-file-input
              :rules="rules"
              accept="image/png, image/jpeg, image/bmp"
              label="Image"
              class="tw-mt-2"
            ></v-file-input> -->

            <validation-provider
              v-slot="{ errors }"
              name="accommodation.location"
              rules="required"
            >
              <j-text-field
                color="secondary"
                v-model="accommodation.location"
                type="text"
                :error-messages="errors"
                label="Location*"
                class="tw-mt-6"
                customClasses="tw-text-left"
              />
            </validation-provider>
            <div class="tw-mt-6 tw-relative">
              <validation-provider
                v-slot="{ errors }"
                name="accommodation.price"
                rules="required"
              >
                <j-text-field
                  color="secondary"
                  v-model="accommodation.price"
                  type="text"
                  :error-messages="errors"
                  label="Cost*"
                  customClasses="tw-text-left"
                />
              </validation-provider>
              <div class="tw-absolute tw--top-1 tw-right-2">
                <span
                  :class="[
                    'tw-text-xs',
                    'tw-mr-4',
                    'tw-cursor-pointer',
                    'tw-font-semibold',
                    'tw-border-black',
                    {
                      'tw-border-b-2':
                        accommodation.priceType === price_types.PER_NIGHT
                    }
                  ]"
                  @click="accommodation.priceType = price_types.PER_NIGHT"
                  >per night</span
                >
                <span
                  :class="[
                    'tw-text-xs',
                    'tw-cursor-pointer',
                    'tw-font-semibold',
                    'tw-border-black',
                    {
                      'tw-border-b-2':
                        accommodation.priceType === price_types.TOTAL
                    }
                  ]"
                  @click="accommodation.priceType = price_types.TOTAL"
                  >total</span
                >
              </div>
            </div>
            <validation-provider
              v-slot="{ errors }"
              name="accommodation.name"
              rules="required"
            >
              <j-text-field
                color="secondary"
                v-model="accommodation.name"
                type="text"
                :error-messages="errors"
                label="Name of Stay*"
                class="tw-mt-6"
                customClasses="tw-text-left"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="accommodation.text"
              rules="required|max:1000"
            >
              <j-textarea
                v-model="accommodation.text"
                outlined
                class="tw-mt-6"
                :error-messages="errors"
                background-color="#fafafa"
                label="Post Message*"
                sublabel="Tell the crew why they'd love this stay."
                placeholder="Look at this place! It has enough beds for all of us and it's close to town. What do you think..."
              />
            </validation-provider>
            <span class="tw-hidden">{{ invalid }}</span>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { PRICE_TYPES } from "@/enums/price-types.js";
export default {
  name: "AccommodationCreate",
  data() {
    return {
      accommodation: {
        name: "",
        tripId: this.$route.params.id,
        destinationId: this.trip ? this.trip.destinations[0].id : null,
        type: "HOTEL",
        priceType: PRICE_TYPES.TOTAL,
        text: "",
        authorId: this.$store.state.auth.user.id,
        linkUrl: "",
        imageUrl: null,
        price: 0,
        location: ""
      },
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!"
      ],
      linkUrl: null,
      metadata: null,
      metadataPending: false,
      price_types: PRICE_TYPES
    };
  },
  watch: {
    accommodation: {
      handler: function () {
        this.$nextTick(() => {
          this.accommodation.price = this.accommodation.price
            .toString()
            .replace(/\D/g, "");
        });
      },
      deep: true
    },
    enable: function (val) {
      if (val) {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: false
        });
      } else {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: true
        });
      }
    },
    linkUrl(val) {
      this.fetchMetadata(val);
      this.accommodation.linkUrl = val;
    }
  },
  computed: {
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    enable() {
      return (
        this.accommodation.text.length > 0 &&
        this.accommodation.name.length > 0 &&
        this.accommodation.location.length > 0
      );
    }
  },
  methods: {
    goTo(route) {
      this.$router.push(route);
    },
    async save() {
      if (!this.trip) {
        await this.$store.dispatch("trip/get", [
          this.$route.params.id,
          {
            query: {
              include: "destination,users,survey,trip_invitation"
            }
          }
        ]);
      }
      let strippedPrice = 0;
      if (this.accommodation.price && this.accommodation.price > 0) {
        strippedPrice =
          parseInt(this.accommodation.price.toString().replace(/\D/g, "")) *
          100;
      }

      this.accommodation.destinationId = this.trip.destinations[0].id;
      await this.$store.dispatch("post-accommodation/create", {
        ...this.accommodation,
        price: strippedPrice
      });
      this.refreshAccomodations();
    },
    async refreshAccomodations() {
      await this.$store.dispatch("post-accommodation/find", {
        query: {
          tripId: this.$route.params.id
        }
      });
      this.goTo({ name: "AccommodationList" });
    },
    async fetchMetadata(url) {
      if (this.checkValidUrl(url)) {
        this.metadataPending = true;
        await this.$store
          .dispatch("metascraper/create", { url })
          .then((res) => {
            if (res) {
              this.metadata = res;
              this.metadataPending = false;
              if (this.metadata.description)
                this.accommodation.text =
                  this.accommodation.text.length > 0
                    ? this.accommodation.text
                    : this.metadata.description;
              if (this.metadata.title && this.metadata.title.length < 200)
                this.accommodation.name = this.metadata.title;
              if (this.metadata.url)
                this.accommodation.linkUrl = this.metadata.url;
              if (this.metadata.image)
                this.accommodation.imageUrl = this.metadata.image.url;
            }
          })
          .catch((err) => {
            this.metadataPending = false;
            console.log(err);
          });
      }
    },
    checkValidUrl(url) {
      try {
        new URL(url);
      } catch (_) {
        return false;
      }
      return true;
    }
  },
  beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Places To Stay",
      bgColor: "#D6BCFF",
      fontColor: "#203848",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: true
    });
    this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
      button: true,
      text: "Post",
      isDisabled: true,
      method: this.save,
      bgColor: "tw-bg-chartreuse"
    });
  }
};
</script>
